import React from "react";
import TextField from "@mui/material/TextField";
import { Autocomplete } from "@mui/material";
import "./DropdownList.scss";
import { createTheme, ThemeProvider } from "@mui/material/styles";

interface DropdownListProps {
  options: string[]; // Assuming options are strings. Adjust if your options structure is different.
  selectedOption: string | null;
  onOptionSelect: (option: string | null) => void; // Callback function for option selection
}

const DropdownList: React.FC<DropdownListProps> = ({
  options,
  selectedOption,
  onOptionSelect,
}) => {
  const theme = createTheme({
    typography: {
      fontFamily: '"DM sans" , sans-serif ',
    },
  });

  return (
    <ThemeProvider theme={theme}>
      <Autocomplete
        className="dropdownListWrapper"
        options={options}
        value={selectedOption} // Controlled value
        classes={{
          inputRoot: "custom-autocomplete-input", // Apply custom class to the input element
          listbox: "custom-autocomplete-dropdown", // Apply custom class to the dropdown list
        }}
        renderInput={(params) => <TextField {...params} label="Search" />}
        onChange={(event, value) => onOptionSelect(value)} // Use the onOptionSelect callback here
      />
    </ThemeProvider>
  );
};

export default DropdownList;
