import React, { useState, useEffect } from 'react';
import { Box,Button } from '@mui/material';
import './Loading.scss';
import sprite from '../../Assets/svgSprite.svg';
import { useSelector } from 'react-redux';
import { RootState } from '../../Redux/store';



// The Loading component, receiving props as per the MapLoadingScreenProps interface
const Loading = () => {

    const mapLoaded = useSelector((state :RootState ) => state.map.isMapLoaded);

    const [showError, setShowError] = useState(false); // State to track if the error message should be shown
  
    // useEffect to handle the side-effect of starting a timer when loading is occurring
    useEffect(() => {
      let timer: NodeJS.Timeout; // Variable to hold our timer for later cleanup
      if (!mapLoaded) {
        setShowError(false); // If not loading, ensure error message is not shown
        timer = setTimeout(() => { // Start a timer when the component is first rendered or when isLoading changes
          setShowError(true); // Set showError to true after 2 minutes to show the error message
        }, 120000); // 120000 milliseconds is 2 minutes
      }
      return () => clearTimeout(timer); // Clean up the timer when the component unmounts or isLoading changes
    }, [mapLoaded]);
  
    if (mapLoaded) return null; // If loading is true, do not render anything
    
  return (
    <Box className='loadingWrapper'>
        <div className="loader">
          <svg>
            <use href={sprite + '#logo'}></use>
          </svg>
          <div>Spatial Digital Twin</div>

          {showError ? (
            <div className='errorLoader'>
                <svg>
                    <use href={sprite + '#error'}></use>
                </svg>
                <p>There was a problem loading this page Try reloading or contact support</p>
                <div>
                <Button className="contact-button" variant="outlined">Contact</Button>
                <Button className="reload-button" variant="contained" onClick={() => window.location.reload()}>
                    Reload Page
                </Button>
                </div>
            </div>
          ) : (
            <div className='loadDots'>
                <div className="circle"></div>
                <div className="circle"></div>
                <div className="circle"></div>
            </div>) }
        </div>
      
    </Box>
  );
};

export default Loading;