import React, { useState, useEffect } from 'react';
import sprite from "../../Assets/svgSprite.svg";
import { useSelector } from "react-redux";
import { RootState } from "../../Redux/store";
import { Button, ButtonGroup, Divider, Box, Switch, Tooltip } from '@mui/material';
import './Sketch.scss';
import Graphic from "@arcgis/core/Graphic";
import GraphicsLayer from "@arcgis/core/layers/GraphicsLayer";
import SketchViewModel from "@arcgis/core/widgets/Sketch/SketchViewModel";
import { SimpleFillSymbol } from "@arcgis/core/symbols";
import { useDispatch } from "react-redux";
import { setShowSketch } from "../../Redux/map/mapSlice";
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import SketchTooltipOptions from "@arcgis/core/views/interactive/sketch/SketchTooltipOptions.js";
import Collection from "@arcgis/core/core/Collection.js";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import FeatureSnappingLayerSource from "@arcgis/core/views/interactive/snapping/FeatureSnappingLayerSource.js";
import SnappingOptions from "@arcgis/core/views/interactive/snapping/SnappingOptions.js";

const SketchComponent = () => {
  // eslint-disable-next-line
  const [selectedTool, setSelectedTool] = useState<string | null>(null);
  const mainView = useSelector((state: RootState) => state.map.view);
  const [sketchViewModel, setSketchViewModel] = useState<SketchViewModel | null>(null);
  const [graphicsLayer, setGraphicsLayer] = useState<GraphicsLayer | null>(null);
  const [selectedGraphics, setSelectedGraphics] = useState<Graphic[]>([]); 
  const dispatch = useDispatch();
  const [enableTooltips, setEnableTooltips] = useState<boolean>(false);
  const [enableSnapping, setEnableSnapping] = useState<boolean>(false);
  const [enableGeometryGuides, setEnableGeometryGuides] = useState<boolean>(false);
  const [enableFeatureToFeature, setEnableFeatureToFeature] = useState<boolean>(false);
  const [showSettings, setShowSettings] = useState<boolean>(false);
  const tools = ["selectFeature", "point", "polyline", "polygon", "rectangle", "circle", "undo", "redo"];

  useEffect(() => {
    const initializeGraphicsLayer = () => {
        if (!mainView) return;

        const layer = new GraphicsLayer();
        mainView.map.add(layer);
        setGraphicsLayer(layer);
    };

    initializeGraphicsLayer();

    return () => {
        if (graphicsLayer) {
            mainView?.map.remove(graphicsLayer);
        }
    };
    // eslint-disable-next-line
}, [mainView]);

const polygonSymbol = new SimpleFillSymbol({
    color: "#1F893F",
    outline: {
        color: "#1F893F",
        width: 3,
    },
});

useEffect(() => {
  const initializeSketchViewModel = () => {
      if (!mainView || !graphicsLayer) return;

      const sketchVM = new SketchViewModel({
          view: mainView,
          layer: graphicsLayer,
          updateOnGraphicClick: true,
          polygonSymbol: polygonSymbol,
      });

      setSketchViewModel(sketchVM);

      if (enableTooltips) {
          const tooltipOptions = new SketchTooltipOptions({
              enabled: true,
          });
          sketchVM.tooltipOptions = tooltipOptions;
      }

      if (enableSnapping) {
          const featureSnappingLayerSource = new FeatureSnappingLayerSource({
              layer: graphicsLayer,
          });
          const collection = new Collection();
          collection.add(featureSnappingLayerSource);
          const snappingOptions = new SnappingOptions({
              enabled: true,
              selfEnabled: true,
              featureEnabled: enableFeatureToFeature,
              featureSources: collection,
              distance: 0,
          });
          sketchVM.snappingOptions = snappingOptions;
      }

    sketchVM?.on("create", (event: any) => {
      setSelectedGraphics([]);
      if (event.state === "complete") {
        sketchVM.update([event.graphic])
      }
    });

    sketchVM?.on("update", (event: any) => {
      setSelectedGraphics([event.graphic]);
      if (event.state === "complete") {
        setSelectedGraphics([]);
      }
    });
};

  initializeSketchViewModel();

  return () => {
      if (sketchViewModel) {
          sketchViewModel.destroy();
      }
  };
  // eslint-disable-next-line
}, [mainView, graphicsLayer, enableTooltips, enableSnapping, enableFeatureToFeature]);

 // Function to handle duplication of graphics
 const handleDuplicate = () => {
  sketchViewModel?.duplicate();
};


// Function to handle deleting graphics
const handleDelete = () => {
  sketchViewModel?.delete();
  setSelectedGraphics([]);
};

const handleToolClick = (tool: string) => {
    if (!sketchViewModel) return;

    setSelectedTool(tool);

    switch (tool) {
        case 'point':
        case 'polyline':
        case 'polygon':
        case 'rectangle':
        case 'circle':
            sketchViewModel.create(tool as any); 
            break;
        case 'undo':
            sketchViewModel.undo();
            break;
        case 'redo':
            sketchViewModel.redo();
            break;
        default:
            break;
    }
};

const handleClose = () => { 
  dispatch(setShowSketch(false));
};
  // Handle toggling enable tooltips switch
  const handleTooltipsChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEnableTooltips(event.target.checked);
  };

  // Handle toggling enable snapping switch
  const handleSnappingToggle = () => {
    setEnableSnapping(!enableSnapping);
    setEnableGeometryGuides(!enableGeometryGuides);
    setEnableFeatureToFeature(!enableFeatureToFeature);
  };
  // Handle toggling enable geometry guides switch
  const handleGeometryGuidesChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEnableGeometryGuides(event.target.checked);
  };

  // Handle toggling enable feature to feature switch
  const handleFeatureToFeatureChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEnableFeatureToFeature(event.target.checked);
  };

  const toggleSettingsContainer = () => {
    setShowSettings(!showSettings);
  };
    

    return (
      <>
        <Box className="sketchContainer">
          <Tooltip title="Close" placement="bottom">
          <svg onClick={() => handleClose()} className="closeBtn">
            <use href={sprite + "#closeIcon"}></use>
          </svg>
          </Tooltip>
          <Tooltip title="More Options" placement="bottom">
          <svg className="moreBtn">
            <use href={sprite + "#moreOptionsDark"}></use>
          </svg>
          </Tooltip>
          <ButtonGroup orientation="horizontal">
            {tools.map((tool) => (
              <Button
                className="sketchBtn"
                onClick={() => handleToolClick(tool)}
              >
                <Tooltip title={tool === 'selectFeature' || tool === 'undo' || tool === 'redo' ? tool : `Draw a ${tool}`} placement="bottom">
                <svg className="sketchIcon">
                  <use href={sprite + "#" + tool}></use>
                </svg>
                </Tooltip>
                {/* Add a divider after specific tools */}
               {["selectFeature", "circle"].includes(tool) && (
                <Divider flexItem orientation="vertical" sx={{ mx: 0.75, my: 0.75 }}/>
          )}
              </Button>
              
            ))};
            <Button className="sketchBtn" onClick={toggleSettingsContainer}>
              <Tooltip title="Settings" placement="bottom">
              <SettingsOutlinedIcon
                className="sketchIcon"
                color="action"
              ></SettingsOutlinedIcon>
              </Tooltip>
            </Button>
          </ButtonGroup>

          {selectedGraphics.length > 0 && (
          <div className="selectedGraphic">
            <div className="selection">
              Selection {selectedGraphics.length}
              <Button className="selectionBtn" onClick={handleDuplicate}>
                <svg className="selectionIcon">
                  <use href={sprite + "#duplicate"}></use>
                </svg>
              </Button>
              <Button className="selectionBtn" onClick={handleDelete}>
                <svg className="selectionIcon">
                  <use href={sprite + "#delete"}></use>
                </svg>
              </Button>
            </div>
          </div>
        )}
        </Box>

        {/* Settings Container */}
        {showSettings && (
          <div className="settingsContainer">
            <div className="settingsHeading">Settings</div>
            <div className="nestedSettings">
              <div className="nestedSetting">
                <div className="settingLabel">Enable Tooltips</div>
                <Tooltip title="Show numerical info while drawing">
                  <InfoOutlinedIcon className="info" color="action" />
                </Tooltip>

                <div className="settingSwitch">
                  <Switch
                    checked={enableTooltips}
                    onChange={handleTooltipsChange}
                  />
                </div>
              </div>
              <div className="nestedSetting">
                <div className="settingLabel">Enable Snapping</div>
                <div className="settingSwitch">
                  <Switch
                    checked={enableSnapping}
                    onChange={handleSnappingToggle}
                  />
                </div>
              </div>
              <div>
                <div className="nestedSetting">
                  <div className="settingLabel1">Geometry Guides</div>
                  <div className="settingSwitch">
                    <Switch
                      checked={enableGeometryGuides}
                      onChange={handleGeometryGuidesChange}
                      disabled={!enableSnapping}
                    />
                  </div>
                </div>
                <div className="nestedSetting">
                  <div className="settingLabel1">Feature to Feature</div>
                  <div className="settingSwitch">
                    <Switch
                      checked={enableFeatureToFeature}
                      onChange={handleFeatureToFeatureChange}
                      disabled={!enableSnapping}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
       
      </>
    );
};

export default SketchComponent;