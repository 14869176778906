import React, { useState, useEffect } from 'react';
import { useSelector } from "react-redux";
import { RootState } from '../../Redux/store';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import CompassVM from "@arcgis/core/widgets/Compass/CompassViewModel";
import { watch } from "@arcgis/core/core/reactiveUtils";
import '../../Styles/_shared.scss';
import sprite from '../../Assets/svgSprite.svg';


const CompassTool = () => {
  const [compassVM, setCompassVM] = useState<CompassVM | null>(null);
  const view = useSelector((state: RootState) => state.map.view)
  const [rotation, setRotation] = useState(0);

  useEffect(() => {
    if (view) {
      setCompassVM(new CompassVM({ view }));
    }
  }, [view]);

  useEffect(() => {
    let handle: __esri.WatchHandle;
    if (compassVM) {
      handle = watch(
        () => compassVM.orientation,
        () => {
          setRotation(compassVM.orientation.z)
        }
      );
    }

    return () => {
      handle?.remove();
    }
  }, [compassVM]);

  return (
    <div className="compass-widget">
      <Tooltip title="Compass" placement="top">
        <IconButton className="toolIcon"
          style={{
            transform: `rotate(${rotation}deg)`
          }}
          onClick={() => compassVM?.reset()}
        >
          <svg>
            <use href={sprite + '#Compass'}></use>
          </svg>
        </IconButton>
      </Tooltip>

    </div >
  )
}

export default CompassTool;