import React, { useState, useEffect, useCallback } from "react";
import { Drawer, Tabs, Tab, Box } from "@mui/material";
import { TableWidget } from "../../Components";

//type
import { SubLayer } from "../LayersList/LayerListComponent.type";

//redux
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../Redux/store";
import { addTableData, toggleTableView } from "../../Redux/map/mapSlice";

//constants
import {} from "../../Constants";
//scss
import "./TableView.scss";
//assets
import sprite from "../../Assets/svgSprite.svg";

interface A11yProps {
  id: string;
  "aria-controls": string;
}

function a11yProps(index: number): A11yProps {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const TableView: React.FC = () => {
  const [drawerHeight, setDrawerHeight] = useState<number | string>(0);
  const [isResizing, setIsResizing] = useState(false);
  const [value, setValue] = useState(0);
  const [activeTableList, setActiveTableList] = useState<SubLayer[]>([]);
  const dispatch = useDispatch();
  const tableStatus = useSelector((state: RootState) => state.map.isTableOpen);
  const layerList = useSelector((state: RootState) => state.map.layerList);
  const [isFullscreen, setFullScreen] = useState(false);
  const [layerInfoList, setLayerInfoList] = useState<SubLayer[]>([]);

  const tableData = useSelector(
    (state: RootState) => state.map.addTableData
  ) as SubLayer;

  const addTab = () => {
    if (activeTableList.length < 10) {
      let newTab: SubLayer = {
        name: "New Tab",
      };
      const newTableData = [...activeTableList, newTab];
      setActiveTableList(newTableData);
      setValue(newTableData.length - 1);
    }
  };

  // Update local state when the Redux state changes
  useEffect(() => {
    if (tableData) {
      let tableList = activeTableList;
      tableList.push(tableData);
      setActiveTableList(tableList);
      dispatch(addTableData(null));
      setValue(tableList.length - 1);
    }
    if (layerList?.length !== 0) {
      let layerInfo: SubLayer[] = [];
      layerList?.forEach((item) => {
        if (item.subLayers?.length === 0) {
          let singleLayer: SubLayer = { name: item.name, url: item.url };
          layerInfo.push(singleLayer);
        } else if (item.subLayers) {
          layerInfo = [...layerInfo, ...item.subLayers]; // Correct way to merge arrays
        }
      });
      setLayerInfoList(layerInfo);
    }
  }, [tableData, activeTableList, layerList, dispatch]);

  // useEffect hook to adjust drawerHeight based on tableStatus
  useEffect(() => {
    if (!tableStatus) {
      setDrawerHeight(0); // Set height to 0 if tableStatus is 0
    } else {
      setDrawerHeight("400px"); // Set height to 200px otherwise
    }
  }, [tableStatus]); // This useEffect depends on tableStatus

  const closeTab = (itemIndex: number) => {
    setValue(0);
    setActiveTableList(
      activeTableList.filter((_, index) => index !== itemIndex)
    );
  };

  const closePanel = () => {
    dispatch(toggleTableView(false));
  };

  // Function to handle the mouse move event
  const handleMouseMove = useCallback(
    (e: MouseEvent) => {
      if (isResizing) {
        // Calculate and update drawer height
        const newHeight = window.innerHeight - e.clientY;
        setDrawerHeight(newHeight + "px");
        setFullScreen(false);
      }
    },
    [isResizing]
  );

  const handleFullScreen = () => {
    setFullScreen(!isFullscreen);
    if (!isFullscreen) {
      setDrawerHeight("101%");
    } else {
      setDrawerHeight("400px");
    }
  };

  const changeTabs = (newValue: number) => {
    setValue(newValue);
  };

  const shortTheString = (text: string) => {
    let shortText = text;
    if (text.length > 20) {
      shortText = text.slice(0, 20) + "...";
    }
    return shortText;
  };

  // Function to stop resizing
  const handleMouseUp = () => setIsResizing(false);

  // Attach and remove the event listeners
  React.useEffect(() => {
    window.addEventListener("mousemove", handleMouseMove);
    window.addEventListener("mouseup", handleMouseUp);

    return () => {
      window.removeEventListener("mousemove", handleMouseMove);
      window.removeEventListener("mouseup", handleMouseUp);
    };
  }, [isResizing, handleMouseMove]);

  const updateActiveTableList = (index: number, updatedLayer: SubLayer) => {
    const newList = [...activeTableList];
    newList[index] = updatedLayer;
    setActiveTableList(newList);
  };

  return (
    <Drawer
      variant="persistent"
      anchor="bottom"
      className="drawerWrapper"
      open={true}
      PaperProps={{
        style: { height: `${drawerHeight}` },
      }}
    >
      <div
        className="drawer-handle"
        onMouseDown={() => setIsResizing(true)}
      ></div>
      <Box className="tableWrapper">
        <Box>
          <Tabs
            value={value}
            aria-label="basic tabs example"
            variant="scrollable"
            scrollButtons="auto"
          >
            {tableStatus && activeTableList.length > 0
              ? activeTableList.map((item, index) => (
                  <div
                    key={index}
                    className={
                      value === index ? "singleTab selected" : "singleTab"
                    }
                  >
                    <Tab
                      className="tabBtn"
                      label={shortTheString(item.name)}
                      {...a11yProps(index)}
                      onClick={() => changeTabs(index)}
                    />
                    <svg className="closeBtn" onClick={() => closeTab(index)}>
                      <use href={sprite + "#closeIcon"}></use>
                    </svg>
                  </div>
                ))
              : null}
          </Tabs>
          <div className="addTabContainer">
            <svg
              className={
                activeTableList.length >= 10 ? "addTab disabled" : "addTab"
              }
              onClick={addTab}
            >
              <use href={sprite + "#plusIcon"}></use>
            </svg>
          </div>
          <div className="rightTopButtons">
            <svg className="fullScreen" onClick={handleFullScreen}>
              <use
                href={
                  isFullscreen
                    ? sprite + "#exitFullScreen"
                    : sprite + "#fullScreen"
                }
              ></use>
            </svg>
            <svg className="closeTableView" onClick={closePanel}>
              <use href={sprite + "#closeIcon"}></use>
            </svg>
          </div>
        </Box>

        {tableStatus && activeTableList.length > 0
          ? activeTableList.map((item, index) => (
              <TableWidget
                key={index}
                value={value}
                index={index}
                tableName={item.name}
                layerInfo={layerInfoList}
                updateTableList={(updatedLayer) =>
                  updateActiveTableList(index, updatedLayer)
                }
              />
            ))
          : null}
      </Box>
    </Drawer>
  );
};

export default TableView;
