import React, { useState, useEffect } from 'react';
import { useSelector } from "react-redux";
import { RootState } from '../../Redux/store';
import NavigationToggle from "@arcgis/core/widgets/NavigationToggle";
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import './NavigationToggle.scss';
import sprite from '../../Assets/svgSprite.svg'


const NavigationToggleTool = () => {
    const [NavToggleVM, setNavToggleVM] = useState<NavigationToggle | null>(null);
    const [defaultNavType, setDefaultNavType] = useState<String>("pan"); // ['pan', 'rotate']
    const view = useSelector((state: RootState) => state.map.view)

    useEffect(() => {
        if (view) {
            setNavToggleVM(new NavigationToggle({ view }));
        }
    }, [view]);

    const handleRotateClick = (navType: String) => {
        if (view && NavToggleVM && NavToggleVM.viewModel.navigationMode !== navType) {
            NavToggleVM.toggle();
            setDefaultNavType(navType);
        }
    }

    return (
        <>
            <Tooltip title="Pan" placement="top">
                <IconButton className={defaultNavType === "pan" ? "navToolIcon selectedNavToolIcon" : "navToolIcon"}
                    onClick={() => handleRotateClick("pan")}
                >
                    <svg>
                        <use href={sprite + '#Pan'}></use>
                    </svg>
                </IconButton>
            </Tooltip>
            <Tooltip title="Rotate" placement="top">
                <IconButton className={defaultNavType === "rotate" ? "navToolIcon selectedNavToolIcon" : "navToolIcon"}
                    onClick={() => handleRotateClick("rotate")}
                >
                    <svg>
                        <use href={sprite + '#Rotate'}></use>
                    </svg>
                </IconButton>
            </Tooltip>
        </>
    );
}

export default NavigationToggleTool;