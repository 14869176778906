import React, { useEffect, useRef, useState } from "react";
import Search from "@arcgis/core/widgets/Search";
import LayerSearchSource from "@arcgis/core/widgets/Search/LayerSearchSource";
import Collection from "@arcgis/core/core/Collection";
import "./Search.scss";
import sprite from "../../Assets/svgSprite.svg";
import { useSelector } from "react-redux";
import { RootState } from "../../Redux/store";

const SearchWidget = () => {
  const searchRef = useRef<HTMLDivElement>(null);
  const [expand, setExpand] = useState(false);
  const mainView = useSelector((state: RootState) => state.map.view);

  useEffect(() => {
    if (mainView && searchRef.current) {

      const searchLayerCollection = new Collection();
      mainView.map.layers.map((layer) => {

        if (layer.type === "integrated-mesh-3dtiles" || layer.type === "group") {
          console.log("Layer type not supported for search", layer);
        } else {
          const searchLayer = new LayerSearchSource({
            layer: layer,
            outFields: ["*"],
            name: layer.title,
          });
          searchLayerCollection.add(searchLayer);

        }
        return searchLayerCollection;
      });

      const searchWidget = new Search({
        view: mainView,
        allPlaceholder: "Search",
        includeDefaultSources: false,
        container: searchRef.current,
        sources: searchLayerCollection,
      });
      searchWidget.visible = true

      searchWidget.on("search-complete", () => {
        searchWidget.clear();
      });
    }
  }, [mainView]);

  return (
    <div
      className={expand ? "search-wrapper" : "search-wrapper close"}
      onMouseEnter={() => setExpand(true)}
      ref={searchRef}
    >
      <svg onClick={() => setExpand(false)} className="closeBtn">
        <use href={sprite + "#closeIcon"}></use>
      </svg>
    </div>
  );
};

export default SearchWidget;
