import React, { useEffect, useState, useCallback } from "react";
import {
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  IconButton,
  Tooltip,
} from "@mui/material";
import { TreeItem, TreeView } from "@mui/x-tree-view";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { BasemapSelector, LayerListComponent } from "../../Components";
import LegendComponent from "../Legend/LegendComponent";
import "./SideMenu.scss";

//assets

import sprite from "../../Assets/svgSprite.svg";

//constant
import { MAIN_MENU } from "../../Constants";

//redux
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../Redux/store";
import { showLayerList } from "../../Redux/map/mapSlice";

const SideMenu = () => {
  const [selectedTab, setSelectedTab] = useState("");
  const [tabPanel, setTabPanel] = useState(<div></div>);
  const [activeTool, setActiveTool] = useState("");
  const [toolPanel, setToolPanel] = useState(<div></div>);
  const [showPopup, setShowPopup] = useState(false);
  const toggleLayerList = useSelector(
    (state: RootState) => state.map.showLayerList
  );
  const dispatch = useDispatch();

  const handleActiveTab = useCallback(
    (item: string) => {
      if (item === selectedTab) {
        item = "";
      }

      const isNewTab = item !== selectedTab;
      const nextItem = isNewTab ? item : "";
      let panelNode = <></>;
      switch (nextItem) {
        case MAIN_MENU[0].name: //query
          panelNode = <></>;
          break;
        case MAIN_MENU[1].name: //Layer
          panelNode = <LayerListComponent />;
          break;
        case MAIN_MENU[2].name: //Legend
          panelNode = <LegendComponent />;
          break;
        case MAIN_MENU[3].name: //BaseMaps
          panelNode = <BasemapSelector />;
          break;
        case MAIN_MENU[4].name: //Tools
          panelNode = <></>;
          break;
        case MAIN_MENU[5].name: //My Maps
          panelNode = <></>;

          break;
        case MAIN_MENU[6].name: //Themes
          panelNode = <></>;
          break;
        default:
          panelNode = <></>;
          break;
      }
      setSelectedTab(item);
      setTabPanel(panelNode);
      setActiveTool("");
      if (isNewTab) {
        dispatch(showLayerList(false));
      }
    },
    [dispatch, selectedTab]
  );

  useEffect(() => {
    if (toggleLayerList) {
      handleActiveTab(MAIN_MENU[1].name);
    }
  }, [toggleLayerList, handleActiveTab]);

  const handleActiveTool = (item: string) => {
    let toolPanel;
    switch (item) {
      case "Tool Name 1":
        toolPanel = <div>This tool is coming soon!</div>;
        setShowPopup(false);
        setActiveTool(item);
        setToolPanel(toolPanel);
        break;

      case "Print":
        setShowPopup(true);
        break;

      case "Analysis":
        break;
      ////handle go back button
      case "":
        setActiveTool("");
        setToolPanel(<></>);
        break;

      default:
        break;
    }
  };

  return (
    <div className="sideMenuWrapper">
      <Drawer
        variant="permanent"
        className={selectedTab ? "drawerWrapper openSubMenu " : "drawerWrapper"}
      >
        <List className="menuList">
          <div className="logoContainer">
            <svg>
              <use href={sprite + "#logo"}></use>
            </svg>
          </div>
          {MAIN_MENU.map((item) => (
            <ListItem
              className="listContainer"
              style={{
                backgroundColor:
                  selectedTab === item.name ? "#F3F3F5" : "white",
              }}
              onClick={() => handleActiveTab(item.name)}
              key={item.name}
            >
              <ListItemIcon>
                <svg>
                  <use href={sprite + "#" + item.name}></use>
                </svg>
              </ListItemIcon>
              <ListItemText className="list-tab" primary={item.name} />
            </ListItem>
          ))}
          <svg className="settingsBtn">
            <use href={sprite + "#Settings"}></use>
          </svg>
        </List>
      </Drawer>
      <div
        className="subMenu"
        style={selectedTab ? { left: "100px" } : { left: "-300px" }}
      >
        <div className="header">Spatial Digital Twin</div>
        <div className="subTitle">Digital Industries</div>
        <div className="selectedTab">{selectedTab}</div>
        <IconButton className="closeBtn" onClick={() => handleActiveTab("")}>
          <ChevronLeftIcon />
        </IconButton>
        {tabPanel}
        {activeTool && !showPopup ? (
          <div className="toolContainer">
            <div className="toolTitleBox">
              <svg onClick={() => handleActiveTool("")}>
                <use href={sprite + "#backBtn"}></use>
              </svg>
              <div className="toolTitle"> {activeTool}</div>
            </div>
            {toolPanel}
          </div>
        ) : selectedTab === "Tools" ? (
          <TreeView
            aria-label="file system navigator"
            defaultCollapseIcon={<ExpandMoreIcon />}
            defaultExpandIcon={<ChevronRightIcon />}
            className="listContainer"
          >
            {MAIN_MENU.find((item) => item.name === selectedTab)?.children?.map(
              (item, index) => (
                <TreeItem
                  nodeId={item.name + String(index + 1)}
                  className={
                    activeTool === item.name
                      ? "listOptions selected"
                      : "listOptions"
                  }
                  key={item.name + index}
                  label={item.name}
                  onClick={() => handleActiveTool(item.name)}
                >
                  {item.children
                    ? item.children.map((tool, toolIndex) => (
                        <div
                          className="single-tool-panel"
                          key={tool.name + index}
                        >
                          <svg className="tool-Icon">
                            <use href={sprite + "#toolIcon"}></use>
                          </svg>
                          <Tooltip title={tool.description}>
                            <svg className="information-Icon">
                              <use href={sprite + "#information"}></use>
                            </svg>
                          </Tooltip>
                          <TreeItem
                            nodeId={tool.name + String(toolIndex + 1)}
                            label={tool.name}
                            className="toolGroup"
                            onClick={() => handleActiveTool(tool.name)}
                          >
                            {tool.children
                              ? tool.children.map((subTool, subToolIndex) => (
                                  <div
                                    className="sub-tool-panel"
                                    key={subTool.name + index}
                                  >
                                    <Tooltip title={subTool.description}>
                                      <svg className="information-Icon">
                                        <use
                                          href={sprite + "#information"}
                                        ></use>
                                      </svg>
                                    </Tooltip>
                                    <TreeItem
                                      nodeId={
                                        subTool.name + String(subToolIndex + 1)
                                      }
                                      label={subTool.name}
                                      onClick={() =>
                                        handleActiveTool(subTool.name)
                                      }
                                    />
                                  </div>
                                ))
                              : null}
                          </TreeItem>
                        </div>
                      ))
                    : null}
                </TreeItem>
              )
            )}
          </TreeView>
        ) : null}
      </div>
    </div>
  );
};

export default SideMenu;
