import { RootState } from "../../Redux/store";
import { useSelector } from "react-redux";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import { useDispatch } from "react-redux";
import NavigationToggle from "@arcgis/core/widgets/NavigationToggle";
import {
  setViewToggle,
  setViewTilt,
  setViewButtonToggle,
} from "../../Redux/map/mapSlice";
import "../../Styles/_shared.scss";
import sprite from "../../Assets/svgSprite.svg";

const ViewToggle = () => {
  const view = useSelector((state: RootState) => state.map.view);
  const viewTilt = useSelector((state: RootState) => state.map.viewTilt);
  const viewButtonToggle = useSelector(
    (state: RootState) => state.map.viewButtonToggle
  );
  const dispatch = useDispatch();

  const handleViewToggle = () => {
    if (view && viewButtonToggle) {
      dispatch(setViewTilt(view.camera.tilt));
      view.goTo({
        center: view.center,
        tilt: 0,
      });
      dispatch(setViewToggle(false));
      dispatch(setViewButtonToggle(false));
      const setNav = new NavigationToggle({ view });
      setNav.viewModel.navigationMode = "pan";
    } else {
      view?.goTo({
        center: view.center,
        tilt: viewTilt,
      });
      dispatch(setViewToggle(true));
      dispatch(setViewButtonToggle(true));
    }
  };
  return (
    <>
      <Tooltip title="Switch view" placement="top">
        <IconButton className="toolIcon" onClick={handleViewToggle}>
          {viewButtonToggle ? (
            <svg>
              <use href={sprite + "#toggle2D"}></use>
            </svg>
          ) : (
            <svg>
              <use href={sprite + "#toggle3D"}></use>
            </svg>
          )}
        </IconButton>
      </Tooltip>
    </>
  );
};

export default ViewToggle;
