import React, { useEffect, useRef, useState } from "react";
import WebMap from "@arcgis/core/WebMap";
import MapView from "@arcgis/core/views/MapView";
import Graphic from "@arcgis/core/Graphic";
import SimpleFillSymbol from "@arcgis/core/symbols/SimpleFillSymbol";
import "./InsetMap.scss";
import sprite from "../../Assets/svgSprite.svg";
import { IconButton, Tooltip } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../Redux/store";
import CloseIcon from "@mui/icons-material/Close";
import { setInsetMapView } from "../../Redux/map/mapSlice";

const InsetMap = () => {
  const overviewDivRef = useRef<HTMLDivElement>(null);
  const extentGraphicRef = useRef<Graphic | null>(null);
  const overviewMapViewRef = useRef<MapView | null>(null);
  const mainView = useSelector((state: RootState) => state.map.view);
  const [showInsetMap, setShowInsetMap] = useState(false);
  const [iconId, setIconId] = useState("StreetMap");
  const insetMapView = useSelector((state: RootState) => state.map.mapView);
  const dispatch = useDispatch();
  const viewToggle = useSelector((state: RootState) => state.map.viewToggle)

  useEffect(() => {
    const initializeOverviewMap = async () => {
      try {
        if (!overviewDivRef.current || !mainView) return;
        if (insetMapView) {
          // Use existing MapView
          overviewMapViewRef.current = insetMapView;
        } else {
          // Create new MapView
          const overviewMap = new WebMap({
              basemap: "satellite",
          });

          overviewMapViewRef.current = new MapView({
            container: overviewDivRef.current,
            map: overviewMap,
            constraints: {
              rotationEnabled: false,
            },
          });

          overviewMapViewRef.current.ui.components = [];

          extentGraphicRef.current = new Graphic({
            geometry: undefined,
            symbol: new SimpleFillSymbol({
              color: [0, 0, 0, 0.2],
              outline: {
                color: [255, 0, 0],
                width: 1,
              },
            }),
          });
          overviewMapViewRef.current.graphics.add(extentGraphicRef.current);
          overviewMapViewRef.current.when(() => {
            extentGraphicRef.current!.geometry = mainView.extent;
            overviewMapViewRef.current?.goTo(mainView.extent);
          });

          const extentWatcher = mainView?.watch("extent", () => {
            try {
              if (
                extentGraphicRef.current &&
                mainView &&
                overviewMapViewRef.current
              ) {
                overviewMapViewRef.current
                  .goTo({
                    target: mainView.extent,
                    scale:
                      mainView.scale *
                      2 *
                      Math.max(
                        mainView.width /
                          (overviewMapViewRef.current.width ?? 1),
                        mainView.height /
                          (overviewMapViewRef.current.height ?? 1)
                      ),
                  })
                  .then(() => {
                    extentGraphicRef.current!.geometry = mainView.extent;
                  })
                  .catch((error) => {
                    if (error.name === "view:goto-interrupted") {
                      console.warn("GoTo operation was interrupted");
                    } else {
                      console.error("Error in goTo operation:", error);
                    }
                  });
              }
            } catch (error) {
              console.error("Error in extentWatcher:", error);
            }
          });

          return () => {
            extentWatcher?.remove();
            overviewMapViewRef.current?.destroy();
          };
        }
        // Dispatch action to store MapView in Redux
        dispatch(setInsetMapView(overviewMapViewRef.current));
      } catch (error) {
        console.error("Error initializing overview map:", error);
      }
    };

    if (showInsetMap) {
      initializeOverviewMap();
    }
  }, [mainView, showInsetMap, dispatch, insetMapView]);

  const handleToggleInsetMap = () => {
    setShowInsetMap((prevState) => !prevState);
    setIconId(showInsetMap ? "StreetMap" : "StreetMapGrey");
  };

  const handleCloseInsetMap = () => {
    setShowInsetMap(false);
    setIconId("StreetMap");
  };

  return (
    <>
      <div className="fmd-icon-container">
        <Tooltip title="InsetMap" placement="top">
          <IconButton
            className="fmd-icon"
            onClick={handleToggleInsetMap}
            style={{ background: showInsetMap ? "#656579" : "#ffffff" }}
          >
            <svg className="streetmapImg">
              <use href={sprite + `#${iconId}`}></use>
            </svg>
          </IconButton>
        </Tooltip>
      </div>
      {showInsetMap && (
        <div className={viewToggle ? "inset-map-container":"inset-map-container-2d"}>
          <IconButton className="insetCloseimg" onClick={handleCloseInsetMap}>
            <CloseIcon />
          </IconButton>
          <div className="overviewDivRef" ref={overviewDivRef}>
            <div id="extentDiv" className="extentDiv"></div>
          </div>
        </div>
      )}
    </>
  );
};

export default InsetMap;
