import React, { useEffect, useState } from "react";
import {
  List,
  ListItemIcon,
  ListItemText,
  Collapse,
  InputAdornment,
  TextField,
  ListItemButton,
  FormControlLabel,
  Box,
  Tooltip,
} from "@mui/material";
import "./LayerListComponent.scss";
import sprite from "../../Assets/svgSprite.svg";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../Redux/store";
import MoreOptionsPopover from "../MoreOptions/MoreOptionsPopover";
import {
  toggleDeactivateLayers,
  fetchLayerList,
} from "../../Redux/map/mapSlice";
import LayerList from "@arcgis/core/widgets/LayerList";
import * as reactiveUtils from "@arcgis/core/core/reactiveUtils";
import Switch from "@mui/material/Switch";
import { Layer, SubLayer } from "./LayerListComponent.type";

const LayerListComponent = () => {
  const [searchQuery, setSearchQuery] = useState("");
  const sceneView = useSelector((state: RootState) => state.map.view);
  const [layers, setLayers] = useState<Layer[]>([]);
  const [showActivatedLayersOnly, setShowActivatedLayersOnly] = useState(false);
  const [expandAll, setExpandAll] = useState(false);
  const dispatch = useDispatch();
  const deactivateLayers = useSelector(
    (state: RootState) => state.map.deactivateLayers
  );
  const [fetchedLayers, setFetchedLayers] = useState<Layer[]>([]);
  const [layerList, setLayerList] = useState<LayerList | null>(null);

  useEffect(() => {
    if (sceneView) {
      const newLayerList = new LayerList({
        view: sceneView,
      });

      // Fetch layers directly from operationalItems
      const fetchedLayers: Layer[] = [];
      reactiveUtils
        .whenOnce(() => newLayerList.operationalItems.length > 0)
        .then(() => {
          newLayerList.operationalItems.forEach((item: any) => {
            if(item.layer && item.layer.type !== "graphics") {
            const subLayers: SubLayer[] = [];
            if (item.children && item.children.length > 0) {
              item.children.forEach((sublayer: any) => {
                subLayers.push({
                  name: sublayer.title,
                  url: sublayer.layer.url,
                  visible: sublayer.visible,
                });
              });
            }
            fetchedLayers.push({
              name: item.title,
              subLayers: subLayers,
              open: expandAll,
              visible: item.visible,
              url: item.layer ? item.layer.url : undefined,
            });
          }
          });

          setFetchedLayers(fetchedLayers);
          dispatch(fetchLayerList(fetchedLayers));
          setLayers(fetchedLayers);
        });
      setLayerList(newLayerList);
      // Clean up by destroying the LayerList instance
      return () => {
        newLayerList.destroy();
      };
    } else {
      // Reset layers if sceneView is not available
      setLayers([]);
      setFetchedLayers([]);
    }
  }, [sceneView, expandAll, deactivateLayers, dispatch]);

  const handleShowActivatedLayersChange = () => {
    setShowActivatedLayersOnly(!showActivatedLayersOnly);
    if (!showActivatedLayersOnly && layerList) {
      const activeLayers: Layer[] = [];
      layerList.operationalItems.forEach((item: any) => {
        if(item.layer && item.layer.type !== "graphics") {
        if (item.visible) {
          activeLayers.push({
            name: item.title,
            subLayers:
              item.children?.map((sublayer: any) => ({
                name: sublayer.title,
                visible: sublayer.visible,
              })) || [],
            open: true,
            visible: item.visible,
            url: item.layer ? item.layer.url : undefined,
          });
        }
      }
      });
      setLayers(activeLayers);
    } else {
      setLayers(fetchedLayers); // Reset to original fetchedLayers
    }
  };

  const handleToggleLayerVisibility = (
    layerName: string,
    layerList: LayerList,
    subLayerName?: string
  ) => {
    if (sceneView) {
      const updatedLayers = layers.map((layer) => {
        if (layer.name === layerName) {
          // Toggle visibility of the main layer
          if (!subLayerName) {
            const updatedLayer = { ...layer, visible: !layer.visible };
            layerList.operationalItems.forEach((item: any) => {
              if (item.title === layerName) {
                item.visible = updatedLayer.visible;
              }
            });
            const deactivateallLayers = layerList.operationalItems.every(
              (item: any) => item.visible === false
            );
            if (deactivateallLayers) {
              dispatch(toggleDeactivateLayers(true));
            } else {
              dispatch(toggleDeactivateLayers(false));
            }
            return updatedLayer;
          } else {
            // Toggle visibility of a sublayer
            const updatedSubLayers = layer.subLayers?.map((subLayer) => {
              if (subLayer.name === subLayerName) {
                const updatedSubLayer = {
                  ...subLayer,
                  visible: !subLayer.visible,
                };
                layerList.operationalItems.forEach((item: any) => {
                  if (item.title === layerName && item.children) {
                    item.children.forEach((sublayer: any) => {
                      if (sublayer.title === subLayerName) {
                        sublayer.visible = updatedSubLayer.visible;
                      }
                    });
                  }
                });
                return updatedSubLayer;
              }
              return subLayer;
            });
            const layerVisibility = updatedSubLayers?.some(subLayer => subLayer.visible);
            return { ...layer, visible : layerVisibility, subLayers: updatedSubLayers };
          }
        }
        return layer;
      });
      setFetchedLayers(updatedLayers); // Update fetchedLayers (for toggling visibility of all layers)
      setLayers(updatedLayers);
    }
  };

  const filterLayers = (layer: Layer, searchQuery: string): Layer | null => {
    // Check if the layer name matches the search query
    const matchesSearch = layer.name.toLowerCase().includes(searchQuery.toLowerCase());
  
    // Check if the layer has sublayers
    const hasSublayers = layer.subLayers && layer.subLayers.length > 0;
  
    // Filter sublayers recursively
    const filteredSubLayers = hasSublayers
      ? layer.subLayers?.map(subLayer => filterLayers(subLayer, searchQuery)).filter(Boolean) as SubLayer[]
      : [];
  
    // Include this layer if it matches the search query or any of its sublayers match
    if (matchesSearch || (filteredSubLayers?.length ?? 0) > 0) {
      return {
        ...layer,
        subLayers: filteredSubLayers
      };
    } else {
      return null; // Exclude this layer from the filtered result
    }
  };
  
  
  const filteredLayers = layers ? layers.map(layer => filterLayers(layer, searchQuery)).filter(Boolean) : [];
  
  // const filteredLayers = layers
  //   ? layers.filter((layer) =>
  //       layer.name.toLowerCase().includes(searchQuery.toLowerCase())
  //     )
  //   : [];

  const handleToggleSubLayers = (layerName: string) => {
    setLayers((prevLayers) =>
      prevLayers.map(
        (layer) =>
          layer.name === layerName ? { ...layer, open: !layer.open } : layer // Toggle the open property
      )
    );
  };

  const handleZoomToLayer = (layerName: string) => {
    if (sceneView && layerList) {
      const layerItem = layerList.operationalItems.find(
        (item: any) => item.title === layerName
      );
      if (layerItem && layerItem.layer && layerItem.layer.fullExtent) {
        sceneView.goTo(layerItem.layer.fullExtent);
      }
    }
  };

  const handleZoomToSubLayer = (layerName: string, subLayerName: string) => {
    if (sceneView && layerList) {
      const layerItem = layerList.operationalItems.find(
        (item: any) => item.title === layerName
      );
      if (layerItem && layerItem.children) {
        const subLayerItem = layerItem.children.find(
          (sublayer: any) => sublayer.title === subLayerName
        );
        if (
          subLayerItem &&
          subLayerItem.layer &&
          subLayerItem.layer.fullExtent
        ) {
          sceneView.goTo(subLayerItem.layer.fullExtent);
        }
      }
    }
  };

  const handleExpandAll = () => {
    setLayers((prevLayers) =>
      prevLayers.map((layer) => ({ ...layer, open: true }))
    );
    setExpandAll(false);
  };

  const handleCollapseAll = () => {
    setLayers((prevLayers) =>
      prevLayers.map((layer) => ({ ...layer, open: false }))
    );
    setExpandAll(true);
  };

  const handleReturnToDefault = () => {

    if (layerList && deactivateLayers === false) {
      layerList.operationalItems.forEach((item: any) => {
        item.visible = false;
      });
      dispatch(toggleDeactivateLayers(true));
    }else if (layerList && deactivateLayers === true) {
      layerList.operationalItems.forEach((item: any) => {
        item.visible = true;
      });
      dispatch(toggleDeactivateLayers(false));
    }
  };

  return (
    <div className="layerListWrapper">
      <TextField
        placeholder="Search Layers (150)"
        variant="outlined"
        value={searchQuery}
        onChange={(e) => setSearchQuery(e.target.value)}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <svg className="searchIcon">
                <use href={sprite + "#SearchIcon"}></use>
              </svg>
            </InputAdornment>
          ),
        }}
        className="searchBox"
      />
      <Box className="boxContainer">
        <FormControlLabel
          control={
            <Switch
              checked={showActivatedLayersOnly}
              onChange={handleShowActivatedLayersChange}
            />
          }
          label="Show only activated layers"
          className="checkboxLabel"
        />
        <span className="buttonsGroup">
          <span className="buttonContainer">
            <svg className="expandAll">
              <use href={sprite + "#expandAll"}></use>
            </svg>
            <span
              className="buttonText"
              onClick={expandAll ? handleExpandAll : handleCollapseAll}
            >
              {expandAll ? "Collapse All" : "Expand All"}
            </span>
          </span>
          <span className="returnIcon" onClick={handleReturnToDefault}>
            <svg className="icon">
              <use
                href={
                  deactivateLayers ? sprite + "#eyeClosed" : sprite + "#eyeOpen"
                }
              ></use>
            </svg>
            <span className="buttonText1">
              {deactivateLayers ? "Return to Default" : "Deactivate All Layers"}
            </span>
          </span>
        </span>
        </Box>
        <List className="layerListItem">
          {filteredLayers.map((layer: Layer | null) => (
            <React.Fragment key={layer!.name}>
              <ListItemButton>
                <ListItemIcon
                  onClick={() => handleToggleSubLayers(layer!.name)}
                  className="openIcon"
                >
                  {layer!.subLayers && layer!.subLayers.length > 0 && (
                    <svg className="layeropenIcon">
                      <use
                        href={
                          layer!.open
                            ? sprite + "#openLayer"
                            : sprite + "#closeLayer"
                        }
                      ></use>
                    </svg>
                  )}
                </ListItemIcon>
                <ListItemText
                  className="itemText"
                  primary={
                    <Tooltip
                      title={layer!.name}
                      placement="bottom"
                      classes={{ tooltip: "custom-tooltip" }}
                    >
                      <span>{layer!.name}</span>
                    </Tooltip>
                  }
                />
                <div className="listItemIcon">
                  <svg
                    className="eyeIcon"
                    onClick={() =>
                      layerList &&
                      handleToggleLayerVisibility(layer!.name, layerList)
                    }
                  >
                    <use
                      href={
                        layer!.visible
                          ? sprite + "#eyeOpenSmall"
                          : sprite + "#eyeClosedSmall"
                      }
                    ></use>
                  </svg>
                  <svg
                    className="Zoomed"
                    onClick={() => handleZoomToLayer(layer!.name)}
                  >
                    <use href={sprite + "#ZoomedTo"}></use>
                  </svg>
                  <MoreOptionsPopover
                    layerInfo={
                      {
                        name: layer!.name,
                        url: layer!.url,
                        visible: layer!.visible,
                      } as SubLayer
                    }
                  />
                </div>
              </ListItemButton>
              <Collapse in={layer!.open} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  {layer!.subLayers?.map((subLayer: SubLayer) => (
                    <ListItemButton key={subLayer.name}>
                      <ListItemText
                        className="subLayerItem"
                        primary={
                          <Tooltip
                            title={subLayer.name}
                            placement="right"
                            classes={{ tooltip: "custom-tooltip" }}
                          >
                            <span>{subLayer.name}</span>
                          </Tooltip>
                        }
                      />
                      <div className="listItemIcon">
                        <svg
                          className="eyeIcon"
                          onClick={() =>
                            layerList &&
                            handleToggleLayerVisibility(
                              layer!.name,
                              layerList,
                              subLayer.name
                            )
                          }
                        >
                          <use
                            href={!layer!.visible ? sprite + "#eyeClosedSmall" :
                              subLayer.visible
                                ? sprite + "#eyeOpenSmall"
                                : sprite + "#eyeClosedSmall"
                            }
                          ></use>
                        </svg>
                        <svg
                          className="Zoomed"
                          onClick={() =>
                            handleZoomToSubLayer(layer!.name, subLayer.name)
                          }
                        >
                          <use href={sprite + "#ZoomedTo"}></use>
                        </svg>
                        <MoreOptionsPopover layerInfo={subLayer ?? undefined} />
                      </div>
                    </ListItemButton>
                  ))}
                </List>
              </Collapse>
            </React.Fragment>
          ))}
        </List>
    </div>
  );
};

export default LayerListComponent;
