import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import SceneView from "@arcgis/core/views/SceneView";
import MapView from "@arcgis/core/views/MapView";
import {
  SubLayer,
  Layer,
} from "../../Components/LayersList/LayerListComponent.type";
import { basemapObject, popupContent } from "./mapSlice.type";

interface MapState {
  view: SceneView | null;
  viewToggle: boolean;
  viewTilt: number;
  viewButtonToggle: boolean;
  isMapLoaded: boolean;
  basemapItems: Array<basemapObject>;
  activeBasemap: basemapObject | null;
  mapView: MapView | null;
  isTableOpen: boolean;
  deactivateLayers: boolean;
  addTableData: SubLayer | null;
  popupContent: popupContent | null;
  hidePopup: boolean;
  showLayerList: boolean;
  layerList: Array<Layer> | null;
  isMeasureToolBarOpen: Boolean;
  measureToolBoxItem : String | null;
  showSketch: boolean;
}

const initialState: MapState = {
  view: null,
  viewToggle: true,
  viewTilt: 0,
  viewButtonToggle: true,
  isMapLoaded: false,
  basemapItems: [],
  activeBasemap: null,
  mapView: null,
  isTableOpen: false,
  deactivateLayers: false,
  addTableData: null,
  popupContent: null,
  hidePopup: false,
  showLayerList: false,
  layerList: null,
  isMeasureToolBarOpen: false,
  measureToolBoxItem: null,
  showSketch: false,
};

const mapSlice = createSlice({
  name: "map",
  initialState,
  reducers: {
    setMapView: (state, action: PayloadAction<any>) => {
      state.view = action.payload;
    },
    setViewToggle: (state, action: PayloadAction<boolean>) => {
      state.viewToggle = action.payload;
    },
    setViewButtonToggle: (state, action: PayloadAction<boolean>) => {
      state.viewButtonToggle = action.payload;
    },
    setViewTilt: (state, action: PayloadAction<number>) => {
      state.viewTilt = action.payload;
    },
    setMapLoaded: (state, action: PayloadAction<boolean>) => {
      state.isMapLoaded = action.payload;
    },
    setBasemapItems: (state, action: PayloadAction<Array<basemapObject>>) => {
      state.basemapItems = action.payload;
    },
    setActiveBasemap: (state, action: PayloadAction<basemapObject>) => {
      state.activeBasemap = action.payload;
    },
    setInsetMapView: (state, action: PayloadAction<any>) => {
      state.mapView = action.payload;
    },
    toggleTableView: (state, action: PayloadAction<boolean>) => {
      state.isTableOpen = action.payload;
    },
    toggleDeactivateLayers: (state, action: PayloadAction<boolean>) => {
      state.deactivateLayers = action.payload;
    },
    addTableData: (state, action: PayloadAction<SubLayer | null>) => {
      state.addTableData = action.payload;
    },
    togglePopup: (state, action: PayloadAction<popupContent | null>) => {
      state.popupContent = action.payload;
    },
    hidePopup: (state, action: PayloadAction<boolean>) => {
      state.hidePopup = action.payload;
    },
    showLayerList: (state, action: PayloadAction<boolean>) => {
      state.showLayerList = action.payload;
    },
    fetchLayerList: (state, action: PayloadAction<Array<Layer> | null>) => {
      state.layerList = action.payload;
    },
    toggleMeasureToolView: (state, action: PayloadAction<boolean>) => {
      state.isMeasureToolBarOpen = action.payload;
    },
    setMeasureToolBoxItem: (state, action: PayloadAction<String | null>) => {
      state.measureToolBoxItem = action.payload
    },
    setShowSketch: (state, action: PayloadAction<boolean>) => {
        state.showSketch = action.payload;
    },
  },
});

export const {
  setMapView,
  setViewToggle,
  setViewTilt,
  setViewButtonToggle,
  setMapLoaded,
  setBasemapItems,
  setActiveBasemap,
  setInsetMapView,
  toggleTableView,
  toggleDeactivateLayers,
  addTableData,
  togglePopup,
  hidePopup,
  showLayerList,
  fetchLayerList,
  toggleMeasureToolView,
  setMeasureToolBoxItem,
  setShowSketch,
} = mapSlice.actions;

export default mapSlice.reducer;
