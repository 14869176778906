import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../Redux/store";
import FeatureLayer from "@arcgis/core/layers/FeatureLayer"; // Ensure this import is correct
import { ThemeProvider, createTheme } from "@mui/material/styles";

import {
  DataGridPremium,
  GridColDef,
  GridToolbarContainer,
  GridToolbarFilterButton,
  GridToolbarExport,
  useGridApiRef,
  GridToolbarColumnsButton,
  GridRowGroupingModel,
  GridRowSelectionModel,
} from "@mui/x-data-grid-premium";
import { LicenseInfo } from "@mui/x-license";
import { Switch, FormControlLabel } from "@mui/material";
//component
import { DropdownList } from "../../Components";

//constants
import { MUI_LICENSE_KEY, ROW_GROUPING } from "../../Constants";
//scss
import "./FeatureTable.scss";
//assets
import sprite from "../../Assets/svgSprite.svg";
import { SubLayer } from "../LayersList/LayerListComponent.type";

// Set the MUI license key
LicenseInfo.setLicenseKey(MUI_LICENSE_KEY);

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
  tableName: string;
  layerInfo: SubLayer[];
  updateTableList: (updatedLayer: SubLayer) => void;
}
interface FieldAliasMap {
  [key: string]: string;
}

const TableWidget: React.FC<TabPanelProps> = ({
  value,
  index,
  tableName,
  layerInfo,
  updateTableList,
}) => {
  const [columns, setColumns] = useState<GridColDef[]>([]);
  const [rows, setRows] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [disableGrouping, setDisableGrouping] = useState<boolean>(false);
  const [showCurrent, isShowCurrent] = useState<boolean>(true);
  const view = useSelector((state: RootState) => state.map.view);
  const apiRef = useGridApiRef();
  const [rowGroupingModel, setRowGroupingModel] =
    React.useState<GridRowGroupingModel>([]);
  const [rowSelectionModel, setRowSelectionModel] =
    React.useState<GridRowSelectionModel>([]);
  const dropdownOptions = layerInfo.map((layer) => layer.name);
  const [selectedOption, setSelectedOption] = useState<string | null>(() => {
    return dropdownOptions.includes(tableName) ? tableName : null;
  });
  const [layerUrl, setLayerUrl] = useState<string>(() => {
    const presetLayer = layerInfo.find((layer) => layer.name === tableName);
    return presetLayer ? presetLayer.url : "";
  });
  const [geometryData, setGeometryData] = useState<any[]>([]);

  //font family
  const theme = createTheme({
    typography: {
      fontFamily: '"DM sans" , sans-serif ',
    },
  });

  const CustomToolbar = () => (
    <ThemeProvider theme={theme}>
      <GridToolbarContainer className="tableToolbar">
        <GridToolbarFilterButton />
        <GridToolbarExport />
        <GridToolbarColumnsButton />
      </GridToolbarContainer>
    </ThemeProvider>
  );

  const handleGrouping = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const foundObject = ROW_GROUPING.find((obj) => obj.name === tableName);
      setRowGroupingModel(foundObject?.grouping as GridRowGroupingModel);
    } else {
      setRowGroupingModel([]);
    }
  };

  const handleCurrent = (event: React.ChangeEvent<HTMLInputElement>) => {
    isShowCurrent(event.target.checked);
  };

  const handleClear = () => {
    setRowSelectionModel([]);
  };

  useEffect(() => {
    if (view) {
      const fetchData = async () => {
        if (view && layerUrl) {
          setLoading(true);
          const featureLayer = new FeatureLayer({
            url: layerUrl,
          });

          const query = featureLayer.createQuery();
          query.where = "1=1";
          query.outFields = ["*"];
          if (showCurrent && view.extent) {
            query.geometry = view.extent; // Query for the current extent
            query.spatialRelationship = "intersects"; // Features that intersect the current view extent
          }

          try {
            const { features } = await featureLayer.queryFeatures(query);
            // Use field aliases for column headers
            const fieldAliases: FieldAliasMap =
              featureLayer.fields.reduce<FieldAliasMap>((acc, field) => {
                acc[field.name] = field.alias;
                return acc;
              }, {});

            const newColumns: GridColDef[] = Object.keys(
              features[0]?.attributes || {}
            ).map((key) => ({
              field: key,
              headerName:
                fieldAliases[key] || key.charAt(0).toUpperCase() + key.slice(1),
              width: 150,
            }));
            const newRows = features.map((feature, index) => ({
              id: index + 1,
              ...feature.attributes,
            }));
            setColumns(newColumns);
            setRows(newRows);
            setGeometryData(features);
          } catch (error) {
            console.error("Failed to query features:", error);
            setColumns([]);
            setRows([]);
          } finally {
            setLoading(false);
          }
        }
      };
      fetchData();
    }
    const foundObject = ROW_GROUPING.find((obj) => obj.name === tableName);
    setDisableGrouping(foundObject === undefined);
  }, [view, layerUrl, showCurrent, tableName]);

  const handleOptionSelect = (option: string | null) => {
    setSelectedOption(option);
    const selectedLayer = layerInfo.find((layer) => layer.name === option);
    setLayerUrl(selectedLayer?.url || null);
    // Update the activeTableList in the parent component
    const updatedLayer = {
      ...selectedLayer,
      name: option || "New Tab",
    };
    updateTableList(updatedLayer);
    if (!option) {
      setColumns([]);
      setRows([]);
    }
  };

  const handleZoomTo = () => {
    if (rowSelectionModel.length === 1) {
      console.log(rowSelectionModel);
      let index: number = +rowSelectionModel[0] - 1;
      console.log(index);
      view?.goTo(geometryData[index].geometry);
    }
  };

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      className="tabPanel"
    >
      <div className="dropdownSelection">
        <DropdownList
          options={dropdownOptions}
          onOptionSelect={handleOptionSelect}
          selectedOption={selectedOption}
        />
      </div>

      {layerUrl ? (
        <>
          <FormControlLabel
            className="currentWindowSwitch"
            control={<Switch defaultChecked onChange={handleCurrent} />}
            label="Show Current Window"
          />
          <FormControlLabel
            className="groupingSwitch"
            control={<Switch onChange={handleGrouping} />}
            label="Show Grouping"
            disabled={disableGrouping}
          />
          {/* no layer has geometry data, disabled it till we have our own map */}
          <FormControlLabel
            className={
              rowSelectionModel.length !== 1 ? "zoomTo disabled" : "zoomTo"
            }
            control={
              <svg>
                <use href={sprite + "#zoomToIcon"}></use>
              </svg>
            }
            label="Zoom to"
            onClick={handleZoomTo}
            disabled={rowSelectionModel.length !== 1}
          />
          <FormControlLabel
            className="clearSelection"
            onClick={handleClear}
            control={
              <svg>
                <use href={sprite + "#closeIcon"}></use>
              </svg>
            }
            label="Clear Selection"
          />
        </>
      ) : null}

      {value === index && (
        <ThemeProvider theme={theme}>
          <DataGridPremium
            className="tableWrapper"
            apiRef={apiRef}
            rows={rows}
            columns={columns}
            loading={loading}
            checkboxSelection={layerUrl ? true : false}
            autosizeOptions={{
              columns: columns as [],
              includeOutliers: true,
              includeHeaders: false,
            }}
            showColumnVerticalBorder
            showCellVerticalBorder
            pagination
            rowGroupingModel={rowGroupingModel ?? undefined}
            slots={layerUrl ? { toolbar: CustomToolbar } : undefined}
            rowSelectionModel={rowSelectionModel}
            onRowSelectionModelChange={(newRowSelectionModel) => {
              setRowSelectionModel(newRowSelectionModel);
            }}
          />
        </ThemeProvider>
      )}
    </div>
  );
};

export default TableWidget;
