import React, { useState, useEffect } from 'react';
import { useSelector } from "react-redux";
import { RootState } from '../../Redux/store';
import BasemapGallery from "@arcgis/core/widgets/BasemapGallery";
import BasemapGalleryVM from "@arcgis/core/widgets/BasemapGallery/BasemapGalleryViewModel";
import Basemap from "@arcgis/core/Basemap";
import BasemapGalleryItem from "@arcgis/core/widgets/BasemapGallery/support/BasemapGalleryItem";
import './BasemapGallery.scss';
import * as reactiveUtils from "@arcgis/core/core/reactiveUtils.js";
import sprite from '../../Assets/svgSprite.svg';
import { setBasemapItems, setActiveBasemap } from '../../Redux/map/mapSlice';
import { useDispatch } from 'react-redux';

interface basemapObject {
  icon: String,
  name: String,
  action: Basemap,
  isActiveBasemap: Boolean,
  snippet: String
}

const BasemapSelector = () => {
  const [basemapGallery, setBasemapGallery] = useState<BasemapGallery>();
  const [basemapItemsAvailable, setBasemapItemsAvailable] = useState<Boolean>(false)
  const view = useSelector((state: RootState) => state.map.view)
  const basemapItems = useSelector((state: RootState) => state.map.basemapItems)
  const activeBasemap = useSelector((state: RootState) => state.map.activeBasemap)
  const dispatch = useDispatch();

  useEffect(() => {
    if (view) {
      setBasemapGallery(new BasemapGallery({ view }));
    }
  }, [view]);

  useEffect(() => {
    if (basemapGallery) {
      const basemapGalleryItemsList: any = basemapGallery?.viewModel.items
      const loadedBasemapItems: Array<basemapObject> = []
      if (basemapItems.length === 0) {
        reactiveUtils.once(
          () => basemapGalleryItemsList.length !== 0)
          .then(() => {
            const basemapGalleryItems: BasemapGalleryVM["items"] = basemapGalleryItemsList.items
            let promises: Array<Promise<Basemap>> = [];
            basemapGalleryItems.map((basemapItem: BasemapGalleryItem) => (
              promises.push(basemapItem.basemap.load())
            ))
            Promise.all(promises).then(loadedBasemaps => {
              loadedBasemaps.map((basemap: Basemap) => (
                loadedBasemapItems.push({
                  icon: basemap["thumbnailUrl"],
                  name: basemap["title"],
                  action: basemap,
                  isActiveBasemap: (basemapGallery?.viewModel.activeBasemap.title === basemap["title"]),
                  snippet: basemap["portalItem"]["snippet"]
                })
              ))
              const activeBasemap = loadedBasemapItems.filter(bm => bm.isActiveBasemap === true)[0]
              setBasemapItemsAvailable(true)
              dispatch(setActiveBasemap(activeBasemap))
              dispatch(setBasemapItems(loadedBasemapItems))
            })
          });
      } else if (basemapItems.length > 0) {
        setBasemapItemsAvailable(true)
      }
    }
  }, [basemapGallery, basemapItems.length, dispatch]);


  return (
    <>
      {basemapItemsAvailable && basemapItems.length > 0 ? (
        <div className='basemapsContainer'>
          {basemapItems && activeBasemap ?
            (basemapItems.map((basemap: any) => (
              <div className="basemapContainer">
                <img alt='' className="basemapThumbnail" src={basemap.icon}
                  onClick={() => { if (view && basemapGallery) basemapGallery.viewModel.activeBasemap = basemap.action; dispatch(setActiveBasemap(basemap)) }} >
                </img>
                {basemap === activeBasemap ? <svg className='basemapStroke'>
                  <use href={sprite + '#Stroke'}></use>
                </svg> : null}
                <div className="basemapCard">
                  <div className='basemapTitle'>{basemap.name}</div>
                  <div className='basemapDescription'>{basemap.snippet}</div>
                </div>
              </div>
            )))
            : (null)}
        </div>
      ) : null}
    </>
  );

}
export default BasemapSelector;
