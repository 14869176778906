import { useSelector } from "react-redux";
import { RootState } from "../../Redux/store";
import { Tooltip, ButtonGroup } from '@mui/material';
import './MeasureToolBar.scss';
import { useDispatch } from "react-redux";
import { toggleMeasureToolView, setMeasureToolBoxItem } from "../../Redux/map/mapSlice";
import IconButton from '@mui/material/IconButton';
import sprite from "../../Assets/svgSprite.svg";

const MeasureToolBar = () => {
    const measureToolStatus = useSelector((state: RootState) => state.map.isMeasureToolBarOpen);
    const measureToolBoxItem = useSelector((state: RootState) => state.map.measureToolBoxItem);
    const measurementTools = ["Measure Distance", "Measure Area", "Slice", "Elevation"];
    const dispatch = useDispatch();

    return (
        <>
            {measureToolStatus ?
                (
                    <ButtonGroup
                        className="measureToolWrapper"
                        aria-label="Measure Tool Button group">
                        <Tooltip title="Close" placement="bottom">
                            <IconButton
                                className="measureToolClose-OptionsButton"
                                onClick={() => { dispatch(toggleMeasureToolView(!measureToolStatus)) }}>
                                <svg>
                                    <use href={sprite + '#MeasureToolClose'}></use>
                                </svg>
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="More Options" placement="bottom">
                            <IconButton
                                className="measureToolClose-OptionsButton"
                                disabled={true}>
                                <svg>
                                    <use href={sprite + '#MeasureToolOptions'}></use>
                                </svg>
                            </IconButton>
                        </Tooltip>
                        <div id="toolbarDiv" className="measureWidget">
                            {measurementTools.map((measuretoolName) => (
                                <Tooltip title={measuretoolName} placement="bottom">
                                    <IconButton
                                        key={measuretoolName} // Ensure a unique key for each button
                                        className="measureToolButton"
                                        onClick={() => {
                                            if (measureToolBoxItem === null) {
                                                dispatch(setMeasureToolBoxItem(measuretoolName))
                                            }
                                        }}
                                    >
                                        <svg>
                                            <use href={sprite + "#" + measuretoolName}></use>
                                        </svg>
                                    </IconButton>
                                </Tooltip>
                            ))}
                        </div>
                    </ButtonGroup>
                )
                : null}
        </>

    );
};

export default MeasureToolBar;