import React from "react";
import SpeedDial from "@mui/material/SpeedDial";
import SpeedDialAction from "@mui/material/SpeedDialAction";
import PrintIcon from "@mui/icons-material/Print";
import { styled } from "@mui/material/styles";
import sprite from "../../Assets/svgSprite.svg";
//redux
import { useDispatch, useSelector } from "react-redux";
import {
  togglePopup,
  showLayerList,
  toggleTableView,
  toggleMeasureToolView,
  setShowSketch,

} from "../../Redux/map/mapSlice";
import { RootState } from "../../Redux/store";

//constants
import { POPUP_CONTENT } from "../../Constants";
//scss
import "./SpeedDial.scss";

const actions = [
  {
    icon: (
      <svg>
        <use href={sprite + "#tableIcon"}></use>
      </svg>
    ),
    name: "Table",
  },
  { icon: <PrintIcon />, name: "Print" },
  {
    icon: (
      <svg>
        <use href={sprite + "#measurementIcon"}></use>
      </svg>
    ),
    name: "Measurement",
  },
  {
    icon: (
      <svg>
        <use href={sprite + "#sketch"}></use>
      </svg>
    ),
    name: "Sketch",
  },
];

const StyledSpeedDial = styled(SpeedDial)(({ theme }) => ({
  "&.MuiSpeedDial-directionUp, &.MuiSpeedDial-directionLeft": {
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
  "&.MuiSpeedDial-directionDown, &.MuiSpeedDial-directionRight": {
    top: theme.spacing(2),
    left: theme.spacing(2),
  },
}));

const SpeedDialButton: React.FC = () => {
  const dispatch = useDispatch();
  const hidePopup = useSelector((state: RootState) => state.map.hidePopup);
  const isTableOpen = useSelector((state: RootState) => state.map.isTableOpen);
  const isLayerListOpenOnce = useSelector(
    (state: RootState) => state.map.layerList
  );
  const measureToolStatus = useSelector(
    (state: RootState) => state.map.isMeasureToolBarOpen
  );

  const handleActionClick = (actionName: string) => {
    switch (actionName) {
      case "Table":
        if (!hidePopup && !isTableOpen) {
          dispatch(togglePopup(POPUP_CONTENT));
        }
        if (!isLayerListOpenOnce) {
          dispatch(showLayerList(true));
        } else {
          dispatch(toggleTableView(!isTableOpen));
        }
        break;

      case "Measurement":
        dispatch(toggleMeasureToolView(!measureToolStatus)); // Dispatch the action to the store
        break;
      
      case "Sketch":
        dispatch(setShowSketch(true));
        break;

      default:
        break;
    }
  };

  return (
    <StyledSpeedDial
      className="speedDialWrapper"
      ariaLabel="SpeedDial"
      icon={
        <svg>
          <use href={sprite + "#speedDial"}></use>
        </svg>
      }
      direction={"left"}
    >
      {actions.map((action) => (
        <SpeedDialAction
          key={action.name}
          icon={action.icon}
          tooltipTitle={action.name}
          onClick={() => handleActionClick(action.name)}
        />
      ))}
    </StyledSpeedDial>
  );
};

export default SpeedDialButton;
