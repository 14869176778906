import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { RootState } from "../../Redux/store";
import ZoomVM from "@arcgis/core/widgets/Zoom/ZoomViewModel";
import { watch } from "@arcgis/core/core/reactiveUtils";
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import '../../Styles/_shared.scss';
import sprite from '../../Assets/svgSprite.svg'

const ZoomTool = () => {
    const view = useSelector((state: RootState) => state.map.view)
    const [zoomVM, setZoomVM] = useState<InstanceType<typeof ZoomVM>>();
    const [disableZoomIn, setDisableZoomIn] = useState(false);
    const [disableZoomOut, setDisableZoomOut] = useState(false);

    useEffect(() => {
        if (view) {
            setZoomVM(new ZoomVM({ view }));
        }
    }, [view]);

    useEffect(() => {
        let handle: __esri.WatchHandle;
        if (zoomVM) {
            handle = watch(
                () => [zoomVM.canZoomOut, zoomVM.canZoomIn],
                () => {
                    setDisableZoomIn(!zoomVM?.canZoomIn);
                    setDisableZoomOut(!zoomVM?.canZoomOut);
                }
            );
        }

        return () => {
            handle?.remove();
        }
    }, [zoomVM]);

    return (
        <>
            <Tooltip title="Zoom In" placement="top">
                <IconButton className="toolIcon"
                    onClick={() => zoomVM?.zoomIn()} disabled={disableZoomIn}>
                    <svg>
                        <use href={sprite + '#ZoomIn'}></use>
                    </svg>
                </IconButton>
            </Tooltip>
            <Tooltip title="Zoom Out" placement="top">
                <IconButton className="toolIcon"
                    onClick={() => zoomVM?.zoomOut()} disabled={disableZoomOut}>
                    <svg>
                        <use href={sprite + '#ZoomOut'}></use>
                    </svg>
                </IconButton>
            </Tooltip>
        </>
    )
};

export default ZoomTool;
