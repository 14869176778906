import { configureStore } from '@reduxjs/toolkit';
import { compose } from 'redux';
import { thunk } from 'redux-thunk';
import rootReducer from './rootReducer';


declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose;
  }
}

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({
    serializableCheck: {
      ignoredActions: ['map/setMapView', 'map/setBasemapItems', 'map/setActiveBasemap', 'map/setInsetMapView'],
      ignoredPaths: ['map.view', 'map.basemapItems', 'map.activeBasemap', 'map.mapView']
    },
  }).concat(thunk)

});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;