import { RootState } from '../../Redux/store';
import { useSelector } from "react-redux";
import { useEffect, useRef, useState } from 'react';
import { useDispatch } from "react-redux";

//assets
import sprite from '../../Assets/svgSprite.svg';

import './MeasureToolBox.scss';
import { setMeasureToolBoxItem } from "../../Redux/map/mapSlice";
import '../../Styles/_shared.scss';
import { Switch } from '@mui/material';
import DirectLineMeasurement3D from '@arcgis/core/widgets/DirectLineMeasurement3D';
import AreaMeasurement3D from '@arcgis/core/widgets/AreaMeasurement3D';
import SliceViewModel from "@arcgis/core/widgets/Slice/SliceViewModel";
import ElevationProfile from '@arcgis/core/widgets/ElevationProfile';
import IconButton from '@mui/material/IconButton';
import * as reactiveUtils from "@arcgis/core/core/reactiveUtils";
import DirectLineMeasurementAnalysis from "@arcgis/core/analysis/DirectLineMeasurementAnalysis";
import AreaMeasurementAnalysis from "@arcgis/core/analysis/AreaMeasurementAnalysis";

//constant
import { DISTANCEMEASUREUNITTYPES, AREAMEASUREUNITTYPES } from "../../Constants";

const MeasureTool = () => {
    const measureToolBoxItem = useSelector((state: RootState) => state.map.measureToolBoxItem);
    const dispatch = useDispatch();
    const mapview = useSelector((state: RootState) => state.map.view)
    const measureContainerRef = useRef<HTMLDivElement>(null);
    const [directLineMeasure, setDirectLineMeasure] = useState<DirectLineMeasurement3D>();
    const [directLineMeasurementAnalysis, setDirectLineMeasurementAnalysis] = useState<DirectLineMeasurementAnalysis>();
    const [areaMeasurementAnalysis, setAreaMeasurementAnalysis] = useState<AreaMeasurementAnalysis>();
    const [areaMeasurement, setAreaMeasurement] = useState<AreaMeasurement3D>();
    const [sliceMeasurement, setSliceMeasurement] = useState<SliceViewModel>();
    const [elevationProfile, setElevationProfile] = useState<ElevationProfile>();
    const [measureInstruction, setMeasureInstruction] = useState<String>('');
    const [measuredDistanceValue, setMeasuredDistanceValue] = useState<any>(null);
    const [directDistanceInUnits, setDirectDistanceInUnits] = useState<any>(null);
    const [horizontalDistanceInUnits, setHorizontalDistanceInUnits] = useState<any>(null);
    const [verticalDistanceInUnits, setVerticalDistanceInUnits] = useState<any>(null);
    const [measuredAreaValue, setMeasuredAreaValue] = useState<any>(null);
    const [areaInUnits, setAreaInUnits] = useState<any>(null);
    const [perimeterInUnits, setPerimeterInUnits] = useState<any>(null);
    const [restartDistanceMeasure, setRestartDistanceMeasure] = useState<Boolean>(false);
    const [restartAreaMeasure, setRestartAreaMeasure] = useState<Boolean>(false);
    const [startNewSlice, setStartNewSlice] = useState<Boolean>(false);
    const [sliceOptions, setSliceOptions] = useState<Boolean>(false);
    const [excludeGroundLayers, setExcludeGroundLayers] = useState<boolean>(false);
    const [distanceUnitList, setDistanceUnitList] = useState<Boolean>(false);
    const [distanceMeasureUnitType, setDistanceMeasureUnitType] = useState<any>(null);
    const [areaUnitList, setAreaUnitList] = useState<Boolean>(false);
    const [areaMeasureUnitType, setAreaMeasureUnitType] = useState<any>(null);
    useEffect(() => {
        if (mapview && measureToolBoxItem !== null) {
            mapview.when(() => {
                if (measureToolBoxItem === 'Measure Distance') {
                    setMeasureInstruction('Click points on the map to measure distance or area.')
                    const directLineMeasureNew = new DirectLineMeasurement3D({
                        view: mapview,
                        unit: "kilometers",
                    });
                    const directLineMeasurementAnalysisNew = new DirectLineMeasurementAnalysis({})
                    setDirectLineMeasure(directLineMeasureNew)
                    setDirectLineMeasurementAnalysis(directLineMeasurementAnalysisNew)
                }
                if (measureToolBoxItem === 'Measure Area') {
                    setMeasureInstruction('Click points on the map to measure distance or area')
                    const areaMeasurementNew = new AreaMeasurement3D({
                        view: mapview,
                        unit: "square-kilometers"
                    });
                    const areaMeasurementAnalysisNew = new AreaMeasurementAnalysis({})
                    setAreaMeasurement(areaMeasurementNew)
                    setAreaMeasurementAnalysis(areaMeasurementAnalysisNew)
                }

                if (measureToolBoxItem === 'Slice') {
                    setMeasureInstruction('Start to slice by clicking and dragging on a surface in the scene. Hold the Shift key to force a vertical slice')
                    const sliceMeasurementNew = new SliceViewModel({
                        view: mapview,
                        tiltEnabled: true
                    });
                    setSliceMeasurement(sliceMeasurementNew)
                }
                if (measureToolBoxItem === 'Elevation' && measureContainerRef.current) {
                    // setMeasureInstruction('')
                    const elavationMeasure = new ElevationProfile({
                        view: mapview,
                        container: measureContainerRef.current,
                        profiles: [
                            {
                                type: "ground"
                            },
                            {
                                type: "view"
                            }
                        ],
                        visibleElements: {
                            legend: true,
                            clearButton: true,
                            settingsButton: true,
                            sketchButton: false,
                            selectButton: false,
                            uniformChartScalingToggle: true,
                        },
                    });
                    setElevationProfile(elavationMeasure)
                }
            })
        }
    }, [measureToolBoxItem, mapview]);

    useEffect(() => {
        if (directLineMeasure) {
            directLineMeasure.viewModel.start()
            reactiveUtils.once(
                () => directLineMeasure?.viewModel.state === "measured")
                .then(() => {
                    setDirectDistanceInUnits(directLineMeasure?.viewModel.measurement.directDistance.text);
                    setHorizontalDistanceInUnits(directLineMeasure?.viewModel.measurement.horizontalDistance.text)
                    setVerticalDistanceInUnits(directLineMeasure?.viewModel.measurement.verticalDistance.text)
                    setMeasuredDistanceValue(directLineMeasure?.viewModel.measurement);
                })
        }
    }, [directLineMeasure, restartDistanceMeasure]);

    useEffect(() => {
        if (directLineMeasure && directLineMeasurementAnalysis && distanceMeasureUnitType && mapview) {
            directLineMeasurementAnalysis.startPoint = directLineMeasure.analysis.startPoint;
            directLineMeasurementAnalysis.endPoint = directLineMeasure.analysis.endPoint;
            directLineMeasurementAnalysis.unit = distanceMeasureUnitType;
            mapview.analyses.add(directLineMeasurementAnalysis);
            mapview.whenAnalysisView(directLineMeasurementAnalysis)
                .then((analysisView: any) => {
                    setDirectDistanceInUnits(analysisView.directLabelText);
                    setHorizontalDistanceInUnits(analysisView.horizontalLabelText)
                    setVerticalDistanceInUnits(analysisView.verticalLabelText)
                })
                .catch((error) => {
                    console.log(error)
                });
        }
    }, [distanceMeasureUnitType, directLineMeasure, directLineMeasurementAnalysis, mapview])

    useEffect(() => {
        if (areaMeasurement) {
            areaMeasurement.viewModel.start()
            reactiveUtils.once(
                () => areaMeasurement?.viewModel.state === "measured")
                .then(() => {
                    setAreaInUnits(areaMeasurement?.viewModel.measurement.area.text);
                    setPerimeterInUnits(areaMeasurement?.viewModel.measurement.perimeterLength.text)
                    setMeasuredAreaValue(areaMeasurement?.viewModel.measurement)
                })
        }
    }, [areaMeasurement, restartAreaMeasure]);

    useEffect(() => {
        if (areaMeasurement && areaMeasurementAnalysis && areaMeasureUnitType && mapview) {
            areaMeasurementAnalysis.geometry = areaMeasurement.analysis.geometry;
            areaMeasurementAnalysis.unit = areaMeasureUnitType;
            mapview.analyses.add(areaMeasurementAnalysis);
            mapview.whenAnalysisView(areaMeasurementAnalysis)
                .then((analysisView: any) => {
                    setAreaInUnits(analysisView.analysisVisualization.areaLabel);
                    setPerimeterInUnits(analysisView.analysisVisualization.perimeterLengthLabel)
                })
                .catch((error) => {
                    console.log(error)
                });
        }
    }, [areaMeasureUnitType, areaMeasurement, areaMeasurementAnalysis, mapview])



    useEffect(() => {
        if (sliceMeasurement) {
            sliceMeasurement.start()
        }
    }, [sliceMeasurement, startNewSlice]);


    useEffect(() => {
        if (elevationProfile) {
            elevationProfile.viewModel.start()
        }
    }, [elevationProfile])

    const handleClickClose = () => {
        dispatch(setMeasureToolBoxItem(null));
        setMeasuredDistanceValue(null);
        mapview?.analyses.removeAll();
        setMeasuredAreaValue(null);
        setSliceOptions(false);
        directLineMeasure?.destroy();
        areaMeasurement?.destroy();
        sliceMeasurement?.destroy();
        elevationProfile?.destroy();
    }

    const handleDistanceUnitChangeClick = (unit: string) => {
        setDistanceUnitList(!distanceUnitList);
        if (measuredDistanceValue !== null) {
            setDistanceMeasureUnitType(unit);
            if (directLineMeasurementAnalysis) {
                mapview?.analyses.remove(directLineMeasurementAnalysis)
            }
        }
    }

    const handleAreaUnitChangeClick = (unit: string) => {
        setAreaUnitList(!areaUnitList);
        if (measuredAreaValue !== null) {
            setAreaMeasureUnitType(unit);
            if (areaMeasurementAnalysis) {
                mapview?.analyses.remove(areaMeasurementAnalysis)
            }
        }
    }

    const handleDistanceMeasureStartClick = () => {
        setRestartDistanceMeasure(!restartDistanceMeasure)
        setMeasuredDistanceValue(null);
        if (directLineMeasurementAnalysis) {
            mapview?.analyses.remove(directLineMeasurementAnalysis)
        }
    }

    const handleAreaMeasureStartClick = () => {
        setRestartAreaMeasure(!restartAreaMeasure)
        setMeasuredAreaValue(null);
        if (areaMeasurementAnalysis) {
            mapview?.analyses.remove(areaMeasurementAnalysis)
        }
    }

    const handleSliceOptionsChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (!sliceMeasurement) return;
        setSliceOptions(!sliceOptions);
        setExcludeGroundLayers(event.target.checked)
        sliceMeasurement.excludeGroundSurface = event.target.checked;
    }

    const handleElevationClick = () => {
        if (elevationProfile) {
            elevationProfile.viewModel.clear();
            elevationProfile.viewModel.start();
        }
    }

    return (
        <>
            {measureToolBoxItem !== null ?
                (
                    <div className='measureToolContainer'>
                        <div className='measureToolHeaderMain'>
                            <div className='measureToolHeader'>
                                <IconButton className="measureToolIcon">
                                    <svg>
                                        <use href={sprite + '#' + measureToolBoxItem}></use>
                                    </svg>
                                </IconButton>
                                <div className={`measureToolHeading ${measureToolBoxItem}`}> {measureToolBoxItem} </div>
                                <IconButton className={`measureToolClose ${measureToolBoxItem}`}
                                    onClick={() => handleClickClose()}>
                                    <svg>
                                        <use href={sprite + '#MeasureToolBoxClose'}></use>
                                    </svg>
                                </IconButton>
                            </div>
                            {measureToolBoxItem !== 'Elevation' ?
                                (
                                    <div className='measureToolOptions'>
                                        <IconButton className="measureToolDeleteIcon"
                                            disabled>
                                            <svg>
                                                <use href={sprite + '#MeasureDelete'}></use>
                                            </svg>
                                        </IconButton>
                                        <IconButton className="measureToolOptionsIcon"
                                            onClick={() => {
                                                if (measureToolBoxItem === 'Measure Distance') {
                                                    setDistanceUnitList(!distanceUnitList);
                                                }
                                                if (measureToolBoxItem === 'Measure Area') {
                                                    setAreaUnitList(!areaUnitList);
                                                }
                                                if (measureToolBoxItem === 'Slice') {
                                                    setSliceOptions(!sliceOptions);
                                                }
                                                if (measureToolBoxItem === 'Slice') {
                                                    setSliceOptions(true);
                                                }
                                            }}>
                                            <svg>
                                                <use href={sprite + '#MeasureToolBoxOptionsIcon'}></use>
                                            </svg>
                                        </IconButton>
                                    </div>) : null}
                        </div>
                        {measureToolBoxItem !== 'Elevation' ?
                            (
                                <div className='measureToolBoxInstruction'>
                                    {measureInstruction}
                                </div>
                            ) : null}
                        {distanceUnitList && measuredDistanceValue !== null ?
                            <div className='measureUnitList'>
                                {DISTANCEMEASUREUNITTYPES.map((unit: String) => (
                                    <div className='unitText'
                                        onClick={() => handleDistanceUnitChangeClick(unit.toLowerCase())}>
                                        {unit}
                                    </div>
                                ))}
                            </div>
                            : null}
                        {measuredDistanceValue !== null ?
                            (
                                <>
                                    <div className='measuredResultsBox'>
                                        <text className='measuredResultsHeading'>
                                            Direct
                                        </text>
                                        <text className='measuredResultsText'>
                                            {directDistanceInUnits}
                                        </text>
                                        <text className='measuredResultsHeading'>
                                            Horizontal
                                        </text>
                                        <text className='measuredResultsText'>
                                            {horizontalDistanceInUnits}
                                        </text>
                                        <text className='measuredResultsHeading'>
                                            Vertical
                                        </text>
                                        <text className='measuredResultsText'>
                                            {verticalDistanceInUnits}
                                        </text>
                                    </div>
                                    <button className='measureToolStartButton'
                                        onClick={() => handleDistanceMeasureStartClick()}>
                                        <svg>
                                            <use href={sprite + '#MeasureToolStartIcon'}></use>
                                        </svg>
                                        <text className='measureStartButtonText'></text>
                                        Start New
                                    </button>
                                </>
                            ) : null}
                        {areaUnitList && measuredAreaValue !== null ?
                            <div className='measureUnitList'>
                                {AREAMEASUREUNITTYPES.map((unit: String) => (
                                    <div className='unitText'
                                        onClick={() => handleAreaUnitChangeClick(unit.toLowerCase())}>
                                        {unit}
                                    </div>
                                ))}
                            </div> : null}
                        {measuredAreaValue !== null ?
                            (
                                <>
                                    <div className='measuredResultsBox'>
                                        <text className='measuredResultsHeading'>
                                            Area
                                        </text>
                                        <text className='measuredResultsText'>
                                            {areaInUnits}
                                        </text>
                                        <text className='measuredResultsHeading'>
                                            Perimeter
                                        </text>
                                        <text className='measuredResultsText'>
                                            {perimeterInUnits}
                                        </text>
                                    </div>
                                    <button className='measureToolStartButton'
                                        onClick={() => handleAreaMeasureStartClick()}>
                                        <svg>
                                            <use href={sprite + '#MeasureToolStartIcon'}></use>
                                        </svg>
                                        <text className='measureStartButtonText'></text>
                                        Start New
                                    </button>
                                </>
                            ) : null}
                        {measureToolBoxItem === 'Slice' ?
                            (
                                <>
                                    <div className='sliceButtonGroup'>
                                        <button className='measureToolStartButton'
                                            onClick={() => setStartNewSlice(!startNewSlice)}>
                                            <svg>
                                                <use href={sprite + '#MeasureToolStartIcon'}></use>
                                            </svg>
                                            <text className='measureStartButtonText'></text>
                                            Start New
                                        </button>
                                    </div>
                                </>
                            )
                            : null}
                        {sliceOptions ? (
                            <div className='sliceOptionsList'>
                                <div className="settingLabel">Exclude Ground Surface and Layers</div>
                                <div className="settingSwitch">
                                    <Switch
                                        checked={excludeGroundLayers}
                                        onChange={handleSliceOptionsChange}
                                    />
                                </div>
                            </div>)
                            : null}
                        {measureToolBoxItem === 'Elevation' ? (
                            <div >
                                <div className='elevationProfileToolContainer' ref={measureContainerRef}> </div>
                                <button className='measureToolStartButton'
                                    onClick={() => handleElevationClick()}>
                                    <svg>
                                        <use href={sprite + '#MeasureToolStartIcon'}></use>
                                    </svg>
                                    <text className='measureStartButtonText'></text>
                                    Start New
                                </button>
                            </div>
                        )
                            : null}
                    </div>
                )
                : null}
        </>)
}

export default MeasureTool;