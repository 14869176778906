import { popupContent } from "./Redux/map/mapSlice.type";
/* 
====== TABLE OF CONTENT ======
==============================

1. MAP CONSTANTS
*/

/*
1. Map =======================
*/

// const SCENE_ITEM = "c8cf26d7acab4e45afcd5e20080983c1";
// const SCENE_ITEM = "92d29869db444e28beab584f696b86c3";
const SCENE_ITEM = "1bf603c0c2b440cabff24a1657f0bd4b";

const MUI_LICENSE_KEY =
  "a3bcea046139a4c96dac1d27c78b79b4Tz04NjY2MCxFPTE3NDIzNTQwMzAwMDAsUz1wcmVtaXVtLExNPXN1YnNjcmlwdGlvbixLVj0y";

/*
1. MENU =======================
*/

const MAIN_MENU = [
  {
    name: "Query",
  },
  {
    name: "Layers",
  },
  {
    name: "Legend",
  },
  {
    name: "Basemaps",
  },
  {
    name: "Tools",
    children: [
      {
        name: "Measure",
        children: [
          {
            name: "Analysis",
            description: "test text",
            children: [
              {
                name: "Tool Name 1",
                description: "test text",
              },
              {
                name: "Tool Name 2",
                description: "test text",
              },
            ],
          },
          {
            name: "Measurement",
            description: "test text",
            children: [
              {
                name: "Tool Name 3",
                description: "test text",
              },
              {
                name: "Tool Name 4",
                description: "test text",
              },
            ],
          },
        ],
      },
      {
        name: "Coordinates",
      },
      {
        name: "Print",
      },
      {
        name: "Add Data",
      },
      {
        name: "Lot and Concession",
      },
      {
        name: "External Services(Street View)",
      },
      {
        name: "Weather",
      },
    ],
  },
  {
    name: "My Maps",
  },
  {
    name: "Themes",
  },
];

/*
1. LayerList =======================
*/

const MORE_INFO = "More Info";
const VIEW_IN_TABLE = "View In Table";
const POPUP_CONTENT = {
  title: "New Table Tab",
  content: "Select a new table view from the layers panel in the dropdown.",
  showTableImg: true,
  showCheckBox: true,
} as popupContent;

/*
2. Table view =======================
*/

const ROW_GROUPING = [
  {
    name: "PedInjuryCounts 2013 - PedInjuryCounts 2013",
    grouping: ["Primary_Rd"],
  },
];

/*
3. Measuremnt Tools =======================
*/

const DISTANCEMEASUREUNITTYPES = ["Kilometers", "Meters", "Feet", "Miles", "Inches"];
const AREAMEASUREUNITTYPES = ["Square-Kilometers", "Square-Meters", "Square-Feet", "Square-Miles", "Square-Inches"];

export {
  MAIN_MENU,
  SCENE_ITEM,
  MUI_LICENSE_KEY,
  MORE_INFO,
  VIEW_IN_TABLE,
  POPUP_CONTENT,
  ROW_GROUPING,
  DISTANCEMEASUREUNITTYPES,
  AREAMEASUREUNITTYPES,
};

