import React, { useState } from "react";
import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import sprite from "../../Assets/svgSprite.svg";
import "./MoreOptions.scss";
import { VIEW_IN_TABLE, MORE_INFO } from "../../Constants";
import { SubLayer } from "../LayersList/LayerListComponent.type";

//redux
import { useDispatch } from "react-redux";
import { addTableData, toggleTableView } from "../../Redux/map/mapSlice";

const MoreOptionsPopover = ({ layerInfo }: { layerInfo: SubLayer }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const dispatch = useDispatch();

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSubmenuClick = (key: string) => {
    switch (key) {
      case MORE_INFO:
        if (layerInfo?.url) {
          window.open(layerInfo.url as string, "_blank"); // Open URL in a new tab
        }
        break;
      case VIEW_IN_TABLE:
        if (layerInfo) {
          dispatch(addTableData(layerInfo));
          dispatch(toggleTableView(true));
        }

        break;

      default:
        break;
    }

    handleClose(); // Close the popover after redirection
  };

  const open = Boolean(anchorEl);
  const id = open ? "more-options-popover" : undefined;

  return (
    <div>
      <svg className="moreOptions" onClick={handleClick}>
        <use href={sprite + "#moreOptions"}></use>
      </svg>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <Box className="optionsBoxContainer" p={2}>
          <Box
            className="optionsBox"
            onClick={() => handleSubmenuClick(MORE_INFO)}
          >
            <InfoOutlinedIcon
              fontSize="small"
              color="action"
              className="icons"
            />
            <Typography variant="body2" className="text">
              {MORE_INFO}
            </Typography>
          </Box>
          <Box className="optionsBoxdisabled">
            <FileDownloadOutlinedIcon
              fontSize="small"
              className="icons"
              color="disabled"
            />
            <Typography variant="body2" className="text" sx={{ color: 'text.disabled', cursor: 'not-allowed' }}>
              Download
            </Typography>
          </Box>
          <Box
            className="optionsBox"
            onClick={() => handleSubmenuClick(VIEW_IN_TABLE)}
          >
            <svg className="openNew">
              <use href={sprite + "#openNew"}></use>
            </svg>
            <Typography variant="body2" className="text">
              {VIEW_IN_TABLE}
            </Typography>
          </Box>
        </Box>
      </Popover>
    </div>
  );
};

export default MoreOptionsPopover;
