import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import WebScene from "@arcgis/core/WebScene";
import SceneView from "@arcgis/core/views/SceneView";
import { setMapView, setMapLoaded } from "../../Redux/map/mapSlice";
import "./Map.scss";
import { Search, WidgetBar, SketchComponent } from "../../Components";
import { RootState } from "../../Redux/store";

const ArcGISMap = () => {
  const dispatch = useDispatch();
  const mapContainerRef = useRef<HTMLDivElement>(null);
  const [sceneView, setSceneView] = useState<SceneView | null>(null);
  const showSketch = useSelector((state: RootState) => state.map.showSketch);

  useEffect(() => {
    let sceneViewObject: SceneView | null = null;

    const loadMap = async () => {
      if (!sceneViewObject && mapContainerRef.current) {
        console.log("Portal Item: ", process.env.REACT_APP_WEBAPP_PORTAL_ITEM);
        const map = new WebScene({
          portalItem: {
            id: process.env.REACT_APP_WEBAPP_PORTAL_ITEM,
          },
        });

        sceneViewObject = new SceneView({
          container: mapContainerRef.current,
          map: map,
          ui: {
            components: ["attribution"],
          },
          popup: {
            dockOptions: { buttonEnabled: false, breakpoint: false },
            visibleElements: { collapseButton: false },
          }
        });
        // Watch for updates to the view's updating property
        sceneViewObject.watch("updating", (isUpdating) => {
          if (!isUpdating) {
            dispatch(setMapLoaded(true));
            dispatch(setMapView(sceneViewObject));
            setSceneView(sceneViewObject);
          }
        });
      }
    };

    loadMap();
  }, [dispatch]);

  return (
    <div className="mapDiv" ref={mapContainerRef}>
      {sceneView && <WidgetBar />}
      {sceneView && <Search />}
      {sceneView && showSketch && <SketchComponent />}
    </div>
  );
};

export default ArcGISMap;
