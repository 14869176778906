import { ZoomTool, CompassTool, ViewToggleWidget, NavigationToggleTool } from '../../Components';
import Box from '@mui/material/Box';
import { ButtonGroup } from '@mui/material';
import { useSelector } from "react-redux";
import { RootState } from '../../Redux/store';
import InsetMap from '../InsetMap/InsetMap';
import './WidgetBar.scss';

const WidgetBar = () => {
  const viewToggle = useSelector((state: RootState) => state.map.viewToggle)
  const view = useSelector((state: RootState) => state.map.view)
  return (
    <>
      {view !== null ? (<Box className='mapToolsFrame'>
        <ButtonGroup className='mapToolsButtonGroup'>
          {/* <HomeTool />  Removed Home from Map Tools*/} 
          {/* <CurrentLocationTool /> Removed Current Location from Map Tools */}
          {/* <BasemapSelector Removed Basemap Gallery from Map tools /> */}
          <ZoomTool />
          <CompassTool />
          {viewToggle && <NavigationToggleTool />}
          <ViewToggleWidget />
          <InsetMap />
        </ButtonGroup>
      </Box >) : (null)}
    </>
  );
};

export default WidgetBar;
