import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import { Button, Checkbox, FormControlLabel } from "@mui/material";
import { PopupWindowProps } from "./PopupWindow.type";

//scss
import "./PopupWindow.scss";
//assets
import imgName from "../../Assets/tablePopup.png";
import sprite from "../../Assets/svgSprite.svg";
//redux
import { useSelector } from "react-redux";
import { RootState } from "../../Redux/store";
import { useDispatch } from "react-redux";
import { hidePopup } from "../../Redux/map/mapSlice";

const PopupWindow: React.FC<PopupWindowProps> = ({
  open,
  title,
  content,
  showTableImg,
  showCheckBox,
  onClose,
}) => {
  const dispatch = useDispatch();
  const isHidePopup = useSelector((state: RootState) => state.map.hidePopup);

  //show popup window to open the layerList
  const handleCheckBox = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(hidePopup(event.target.checked));
  };

  return (
    <Dialog className="popupWrapper" open={open} onClose={onClose}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <p>{content}</p>
        {showTableImg ? <img src={imgName} alt="pop img"></img> : null}
        {showCheckBox ? (
          <FormControlLabel
            control={
              <Checkbox checked={isHidePopup} onChange={handleCheckBox} />
            }
            label="Don't show this popup again"
          />
        ) : null}
      </DialogContent>
      <DialogActions>
        <Button className="closeBtn" onClick={onClose}>
          <svg>
            <use href={sprite + "#closeIcon"}></use>
          </svg>
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default PopupWindow;
