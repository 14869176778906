import { useEffect } from 'react';
import { RootState } from "../../Redux/store";
import { useSelector } from "react-redux";
import Legend from "@arcgis/core/widgets/Legend";
import './Legend.scss';

const LegendComponent = () => {
    const sceneView = useSelector((state: RootState) => state.map.view);

    useEffect(() => {
        if (!sceneView) return;

        const legend = new Legend({
            view: sceneView,
            container: "legendContainer"
        });

        return () => {
            if (legend) {
                legend.destroy();
            }
        };
        
    }, [sceneView]);

    return (
        <div className='mainContainer'>
            <div id="legendContainer"></div>
        </div>
    );
};

export default LegendComponent;
