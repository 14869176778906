import React, { useState, useEffect, useCallback } from "react";
import {
  Loading,
  SideMenu,
  SpeedDialButton,
  TableView,
  PopupWindow,
  MeasureToolBar,
  MeasureTool,
} from "../../Components";
import ArcGISMap from "../../Components/Map/Map";
import "./LandingPage.scss";
import Button from "@mui/material/Button";


//redux
import { useSelector } from "react-redux";
import { RootState } from "../../Redux/store";
import { useDispatch } from "react-redux";
import { togglePopup } from "../../Redux/map/mapSlice";
import { popupContent } from "../../Redux/map/mapSlice.type";

const LandingPage = () => {
  const [open, setOpen] = useState(false);
  const popupWindowContent = useSelector(
    (state: RootState) => state.map.popupContent
  ) as popupContent;
  const dispatch = useDispatch();

  const handleOpen = () => setOpen(true);

  const handleClose = useCallback(() => {
    setOpen(false);
    dispatch(togglePopup(null));
  }, [dispatch]);

  useEffect(() => {
    if (popupWindowContent) {
      handleOpen();
    } else {
      handleClose();
    }
  }, [dispatch, popupWindowContent, handleClose]);

  return (
    <div className="landingPage">
      <ArcGISMap />
      <SideMenu></SideMenu>
      <Loading></Loading>
      <SpeedDialButton></SpeedDialButton>
      <TableView></TableView>
      <Button variant="outlined" onClick={handleOpen}>
        Open Popup
      </Button>
      <PopupWindow
        open={open}
        title={popupWindowContent?.title}
        content={popupWindowContent?.content}
        showTableImg={popupWindowContent?.showTableImg}
        showCheckBox={popupWindowContent?.showCheckBox}
        onClose={handleClose}
      />
      <MeasureToolBar></MeasureToolBar>
      <MeasureTool></MeasureTool>
    </div>
  );
};

export default LandingPage;
